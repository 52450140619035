.loader-page-wrapper {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgba(var(--background-color-rgb-values), 0.6); */
}

.loader-page-wrapper.main-design {
    background-color: rgba(var(--background-color-rgb-values-main-design), 0.6);   
}

.loader-page-wrapper.dark {
    background-color: rgba(var(--background-color-rgb-values), 1);
}

.loader-page-wrapper.main-design.dark {
    background-color: rgba(var(--background-color-rgb-values-main-design), 1);
}
